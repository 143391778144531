<template>
  <div class="p-8">
    <div class="flex items-center mb-4">
      <h2 class="text-lg font-semibold">Admin Audit Trail</h2>
    </div>

    <!-- Table -->
    <datatable
      ref="table"
      :ajax="true"
      :ajax-pagination="true"
      :url="url"
      :query="searchQuery"
      :columns="columns"
      :class-name="rowClass"
    >
      <template v-slot:td-1="{ item: user }">
        <div class="text-sm font-semibold">{{ user?.row.action }}</div>
      </template>

      <template v-slot:td-2="{ item: user }">
        <div class="text-sm text-gray-600">{{ user?.row.adminId }}</div>
      </template>
      <template v-slot:td-3="{ item: user }">
        <div class="text-sm">
          {{ user?.row.isSelfAlteration }}
        </div>
      </template>
      <template v-slot:td-4="{ item: user }">
        <div class="text-sm">
          {{ new Date(user?.row.activityTimestamp).toLocaleString() }}
        </div>
      </template>
      <template v-slot:td-5="{ item: user }">
        <div class="flex gap-2">
          <button
            class="btn btn-blue text-xs"
            @click.prevent="openModal(user.row)"
          >
            View More
          </button>
        </div>
      </template>
    </datatable>

    <!-- modal edit -->

    <!-- Create Role Modal -->
    <modal
      ref="viewAdminAuditModal"
      className="w-11/12 sm:w-3/5 lg:w-4/5 p-6 rounded-md shadow-lg bg-white overflow-auto"
    >
      <template>
        <div class="flex justify-between items-center border-b pb-4">
          <h2 class="text-xl font-semibold">View Admin Audit</h2>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <p>User Action</p>
          <p>{{ adminAudit?.action || 'N/A' }}</p>

          <p>Admin Id</p>
          <p>{{ adminAudit?.adminId || 'N/A' }}</p>

          <p>Is Self Alteration</p>
          <p>{{ adminAudit.isSelfAlteration?.toString() }}</p>

          <p>Activity Stamp</p>
          <p>{{ new Date(adminAudit.activityTimestamp).toLocaleString() }}</p>

          <p>Target user</p>
          <p>
            {{
              adminAudit?.targetUser &&
              typeof adminAudit?.targetUser === 'object'
                ? adminAudit?.targetUser?.firstName +
                  ' ' +
                  adminAudit?.targetUser?.lastName
                : adminAudit?.targetUser
            }}
          </p>
          <p>Target user Email</p>
          <p>
            {{
              adminAudit?.targetUser &&
              typeof adminAudit?.targetUser === 'object'
                ? adminAudit?.targetUser?.email
                : 'N/A'
            }}
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'admin-audit',
  data() {
    return {
      url: `${this.$baseurl}/audit-trail/v1/admin-audit`,
      searchQuery: '',
      columns: [
        { name: 'userAction', th: 'user Action', sortable: false },

        { name: 'adminId', th: ' admin Id', sortable: false },
        { name: 'isSelfAlteration', th: 'is Self Alteration', sortable: false },
        { name: 'activityStamp', th: 'Activity Stamp', sortable: false },

        { name: 'actions', th: 'Actions', sortable: false },
      ],
      adminAudit: null,
    };
  },
  methods: {
    openModal(user) {
      this.adminAudit = user;
      this.$refs.viewAdminAuditModal.open();
    },
  },
};
</script>
